function setEqualHeight() {
    // $(".grid-product .grid-element-content-header-image").matchHeight();
    $(".grid-product .grid-element-title").matchHeight();
    $(".grid-product .product-options-wrapper").matchHeight();
    $(".grid-product .prices-small").matchHeight();
    $(".grid-product .productshortattributes").matchHeight();
    $(".grid-product .prices-small").matchHeight();
    $(".luna-vita .grid-element-content").matchHeight();
    $(".grid-product .nakup").matchHeight();
    $(".social-media-box").matchHeight();
    // $(".gpx-box").matchHeight();
    $(".gpx-upload").matchHeight();
    $(".news-list-017 .title-wrapper").matchHeight();
    $(".news-list-017 .news-content-wrapper").matchHeight();
    $(".grid-menu-item-011-01").matchHeight();
    $(".grid-menu-item-012").matchHeight();
    $(".grid-news-item-017 article").matchHeight();
    $(".grid-news-item-017 news-image-wrapper").matchHeight();
    $(".grid-news-item-017 news-content-wrapper").matchHeight();
}

$(window).on("load", function() {
    setEqualHeight();
});
$(window).resize(function() {
    setEqualHeight();
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height", maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height", "");
            $('#main-menu').css("height", "");
        });
    }
});

$(window).bind('scroll', function() {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        scroll_limit = 160,
        initial_cart_top = -80,
        target_cart_top = 10,
        margin_top,
        cart_top,
        initial_logo_img_top,
        target_logo_img_top,
        logo_img_top;
    switch (current_breakpoint) {
        case "xxlarge":
            scroll_limit = 160;
            initial_cart_top = -80;
            target_cart_top = 10;
            break;
        case "xlarge":
            scroll_limit = 140;
            initial_cart_top = -70;
            target_cart_top = 5;
            break;
        case "large":
            scroll_limit = 130;
            initial_cart_top = -65;
            target_cart_top = 0;
            break;
        case "medium":
            scroll_limit = 40;
            initial_logo_img_top = 10;
            target_logo_img_top = 5;
            initial_logo_img_height = 70;
            target_logo_img_height = 50;
            break;
        case "small":
            scroll_limit = 40;
            initial_logo_img_top = 5;
            target_logo_img_top = 5;
            initial_logo_img_height = 70;
            target_logo_img_height = 50;
            break;
        case "xsmall":
            scroll_limit = 40;
            initial_logo_img_top = 5;
            target_logo_img_top = 5;
            initial_logo_img_height = 70;
            target_logo_img_height = 50;
            break;
        default:
            scroll_limit = 160;
            initial_cart_top = -80;
            target_cart_top = 10;
    }
    if (current_breakpoint_num > 3) {
        if ($(window).scrollTop() <= scroll_limit) {
            margin_top = -$(window).scrollTop();
            cart_top = initial_cart_top + (target_cart_top - initial_cart_top) * $(window).scrollTop() / scroll_limit;
        } else {
            margin_top = -scroll_limit
            cart_top = target_cart_top;
        }
        $("#header-wrapper").css("margin-top", margin_top + "px");
        $("#nav-cart-wrapper").css("top", cart_top + "px");
        $("#logo img").css({
            "top": "",
            "height": ""
        });
    } else {
        if ($(window).scrollTop() <= scroll_limit) {
            margin_top = -$(window).scrollTop();
            logo_img_top = initial_logo_img_top + (target_logo_img_top - initial_logo_img_top) * $(window).scrollTop() / scroll_limit;
        } else {
            margin_top = -scroll_limit;
            logo_img_top = target_logo_img_top;
        }
        $("#header-wrapper").css("margin-top", margin_top + "px");
        /*
        $("#nav-cart-wrapper").css("top", "");
        if (current_breakpoint_num > 1) {
            $("#logo a").css({
                "padding-top": logo_img_top + "px",
                "padding-bottom": logo_img_top + "px"
            });
            $("#logo img").css({
                "top": logo_img_top + "px"
            });
        } else {
            $("#logo a").css({
                "padding-top": "",
                "padding-bottom": ""
            });
            $("#logo img").css({
                "top": "",
                "height": ""
            });
        }
        */
    }
});
/*
if ('serviceWorker' in navigator) {

    navigator.serviceWorker.getRegistrations().then(function(registrations) {

    for(let registration of registrations) {

            registration.unregister()

    }}).catch(function(err) {

        console.log('Service Worker registration failed: ', err);

    });
}
*/

if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
        navigator.serviceWorker.register('/serviceworker.min.js').then(function(reg) {

            if (reg.installing) {
                console.log('Service worker installing');
            } else if (reg.waiting) {
                console.log('Service worker installed');
            } else if (reg.active) {
                console.log('Service worker active');
            }

        }).catch(function(error) {
            // registration failed
            console.log('Registration failed with ' + error);
        });
    });
} else {
    console.log('No Service worker Available');
}
